import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  Involvement,
  Visit,
  WorkedWith,
  Project,
  Car,
} from "../components/parts"

const Klinify = () => {
  return (
    <Layout title="Klinify">
      <Title heading="Klinify" sub="Clinic management software" />
      <Overview
        project="klinify"
        text={[
          "Klinify is a cloud-based clinic management system used by over 800 doctors in Malaysia to manage their clinics more efficiently.",
          "I was involved in prototyping and building end-to-end some of the premium features.",
        ]}
      />
      <Project
        heading="COVID-19"
        sub="Critical information for the doctors"
        style={{ marginBottom: 0 }}
      />
      <div className="section-bottom">
        <Car
          images={[2, 4, 1, 3].map(i => `/images/klinify/covid-${i}.png`)}
          bordered
        />
      </div>
      <Project
        heading="E-consult booking"
        sub="Patient side of e-consult booking"
        style={{ marginBottom: 0 }}
      />
      <div className="section-bottom">
        <Car
          images={[1, 2, 3].map(i => `/images/klinify/patient-${i}.png`)}
          bordered
        />
      </div>
      <Project
        heading="Clinic analytics"
        sub="Customisable monthly reports for clinics"
        style={{ marginBottom: 0 }}
      />
      <div className="section-bottom">
        <Car
          images={[1, 2, 3, 4].map(i => `/images/klinify/analytics-${i}.png`)}
          bordered
        />
      </div>
      <Project
        heading="PharmaConnect"
        sub="Connecting pharmas to the doctors / Not released"
      />
      <Toolset tools={["reactjs", "python", "typescript", "flow"]} />
      <Involvement project="klinify" />
      <Visit project="klinify" />
      <WorkedWith project="klinify" />
    </Layout>
  )
}

export default Klinify
